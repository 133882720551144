import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './_button_effect.scss';

const ButtonEffect = ({ bgColor, bgColorHover, textColor, children, ...props }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button className="btn-effect" {...props} style={{
            backgroundColor: isHovered ? bgColor : bgColorHover,
            color: textColor
        }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        ><span>{children}</span></button>
    );
};

ButtonEffect.propTypes = {
    bgColor: PropTypes.string,
    bgColorHover: PropTypes.string,
    textColor: PropTypes.string,
    children: PropTypes.string
}

export default ButtonEffect;
