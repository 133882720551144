import ThemedButtonEffect from "../../UI/buttons/ThemedButtonEffect";
import Typewriter from 'typewriter-effect';
import myCv from '../../../assets/documents/cv_laurent_monjou.pdf';
import './_home.scss';


export default function Home(props) {
    return (
        <section id="home">
            <div className="container">
                <div className="home-text-content">
                    <h1>
                        Hello<br />Je suis Laurent Monjou, développeur web full-stack
                    </h1>
                    <br />
                    <Typewriter
                        options={{
                            strings: ['<span>Spécialisé dans le développement back-end</span>'],
                            autoStart: true,
                            loop: true,
                            delay: 30,
                            pauseFor: 8000,
                        }}
                    />
                </div>

                <a href={myCv}><ThemedButtonEffect>Télécharger CV</ThemedButtonEffect></a>
            </div>
        </section >
    );
}
