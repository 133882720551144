import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NoMatch from './components/pages/NoMatch';
import Site from './components/pages/Site';
import { ThemeContext, themes } from './ThemeContext';

function App(props) {

  return (
    <>

      <ThemeContext.Provider value={themes.default}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Site />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </BrowserRouter>
      </ThemeContext.Provider>

    </>
  )
}

export default App;
