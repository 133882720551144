import reactlogo from '../assets/images/react-logo.png';
import symfonylogo from '../assets/images/symfony-logo.png';

export const FORMATIONS = {
    projet1: {
        img: reactlogo,
        title: 'Site de gestion de recettes de cuisine',
        url: '',
        resume: 'React, AdonisJS, Api Rest',
        description: 'Développement de la partie frontend avec la bibliothèque React afin de persister des recettes sur un serveur et une base de données. La partie serveur (framework Adonisjs) était fournie.',
    },
    projet2: {
        img: symfonylogo,
        title: 'Site d\'agence immobilière',
        url: '',
        resume: 'Symfony, Mysql, Webpack Encore, Mapbox, Sass',
        description: 'Tour d’horizon de Symfony à travers la création d’une application concrète. La mise en place d\'un site qui référence des biens immobiliers avec possibilité de les filter en fonction de la localisation, la superficie et autres critères.',
    },
    projet3: {
        img: symfonylogo,
        title: "Site Ecommerce",
        url: 'https://www.noubliepasdecrire.com',
        resume: 'Symfony, Easyadmin, Stripe, Webpack Encore, Sass, Bootstrap',
        description: 'Site ecommerce avec un backoffice (easyadmin), un système de paiement (stripe) et la gestion de mails (MailJet, MailTrap). Objectif : manipuler le framework Symfony dans des applications plus complexes.',
    },
    projet4: {
        img: reactlogo,
        title: 'Site pour un Zoo',
        url: 'http://www.sitacados.com',
        resume: 'PHP, Mysql, Api Rest, React',
        description: 'Conception d\'un site vitrine pour un zoo avec mise en relation de la partie front-end REACT avec une partie back-end PHP et une API Rest.',
    },
}
