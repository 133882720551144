import React from 'react';

export const themes = {
    default: {
        bgColorBody: '#f5f5f5',
        bgColorPrimary: '#040f17', // Navbar, Button Hover (anciennement 0b1a25)
        bgColorSecondary: '#379634', // Button
        bgColorTertiary: '#297373', // Navbar Link Hover
        bgColorQuaternary: '#297373', // progressBar...
        bgColorSection: '#CBD2D0',
        textColorPrimary: 'rgb(70, 70, 70)', // text color content
        textColorSecondary: 'rgb(230, 230, 230)', // text button, navbar ...
        linkColor: '#CF5C36',
        linkColorHover: '#91B7C7',
        navBarTogglerBgColor: 'rgba(128, 136, 144, 0.5)',
        navBarTogglerLineBgColor: "#fff"
    },
    other: {
        bgColorBody: '#f5f5f5',
        bgColorPrimary: '#0B2531', // Navbar, Button Hover
        bgColorSecondary: '#CF5C36', // Button
        bgColorTertiary: '#91B7C7', // Navbar Link Hover
        bgColorQuaternary: '#DEB841', // progressBar ...
        textColorPrimary: 'rgb(70, 70, 70)', // text color content
        textColorSecondary: 'rgb(230, 230, 230)', // text button, navbar ...
        linkColor: '#CF5C36',
        linkColorHover: '#91B7C7',

        navBarTogglerBgColor: 'rgba(128, 136, 144, 0.5)',
        navBarTogglerLineBgColor: "#fff"
    },
};


export const ThemeContext = React.createContext(themes.default);
