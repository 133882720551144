import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll'
import { ThemeContext } from '../../../ThemeContext';

const LinkScroll = ({ close, children, hash, ...props }) => {
    const theme = useContext(ThemeContext);

    return (
        <li><Link href={"#" + hash} className="nav-link nav-link-flex" to={hash} name={hash} spy={true} offset={-300} onClick={close} {...props}
            style={{
                color: theme.textColorSecondary
            }}

            activeStyle={{
                backgroundColor: theme.bgColorTertiary,
                color: theme.textColorSecondary
            }}
        >{children}</Link></li >
    );
}

LinkScroll.propTypes = {
    close: PropTypes.func,
    children: PropTypes.node,
    hash: PropTypes.string
}

export default LinkScroll;