import { useEffect } from "react";

function useOverflowControl(isOpen) {
    useEffect(() => {
        const bodyElement = document.body;
        const htmlElement = document.documentElement;

        if (isOpen) {
            htmlElement.style.overflow = 'hidden';
            bodyElement.style.overflow = 'hidden';
            htmlElement.style.position = 'relative';
            bodyElement.style.position = 'relative';
            htmlElement.style.height = '100%';
        } else {
            resetOverflow(bodyElement, htmlElement)
        }

        return () => {
            resetOverflow(bodyElement, htmlElement)
        };
    }, [isOpen]);
}

function resetOverflow(bodyElement, htmlElement) {
    htmlElement.style.overflow = '';
    bodyElement.style.overflow = '';
    htmlElement.style.position = '';
    bodyElement.style.position = '';
    htmlElement.style.height = '';
}

export default useOverflowControl;