import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../ThemeContext';

const Toggler = ({ isNavOpen, action }) => {
    const theme = useContext(ThemeContext);
    return (
        <button type="button" className="nav-toggler"
            style={{ backgroundColor: isNavOpen ? 'inherit' : theme.bgColorPrimary, opacity: 0.8 }}
            aria-label="ouvrir ou fermer le menu"
            onClick={action}>
            <span className="nav-toggler-line line1" style={{ backgroundColor: theme.textColorSecondary }}></span>
            <span className="nav-toggler-line line2" style={{ backgroundColor: isNavOpen ? 'inherit' : theme.textColorSecondary }}></span>
            <span className="nav-toggler-line line3" style={{ backgroundColor: theme.textColorSecondary }}></span>
        </button >
    );
}

Toggler.propTypes = {
    isNavOpen: PropTypes.bool,
    action: PropTypes.func,
}

export default Toggler;