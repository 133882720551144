import { useContext } from 'react';
import { ThemeContext } from '../../../ThemeContext';
import ButtonEffect from "./ButtonEffect";

const ThemedButtonEffect = ({ children, ...props }) => {
    const theme = useContext(ThemeContext);

    return (
        <ButtonEffect bgColor={theme.bgColorPrimary} bgColorHover={theme.bgColorSecondary} textColor={theme.textColorSecondary}>{children}</ButtonEffect>
    );
};

export default ThemedButtonEffect;