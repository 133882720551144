export default function Footer(props) {

    const date = new Date();
    const fullYear = date.getFullYear();

    return (
        <footer>
            <div className="container">© {fullYear} Laurent Monjou, Tous droits réservés</div>
        </footer>
    );
}