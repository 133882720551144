import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './_modal.scss';
import useOverflowControl from '../hooks/useOverflowControl';

const MyModal = ({ isOpen, close, children }) => {
    const buttonRef = useRef(null);

    useOverflowControl(isOpen);

    useEffect(() => {
        const modal = document.querySelector('.modal-container.active');
        const button = modal.querySelector('.modal-close');
        button.focus();
    }, []);

    return createPortal(
        <>
            {isOpen ?
                <div className="modal-container active"
                    role="dialog"
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-desc"
                    aria-modal="true"
                    aria-hidden="true"
                >
                    <div className="modal">
                        <div className="modal-content">
                            {children}
                        </div>
                        <button aria-label="fermer" className="modal-close" onClick={close} ref={buttonRef} tabIndex="0">X</button>
                    </div>

                </div>
                : ''}
        </>, document.body
    );
};

export default MyModal;