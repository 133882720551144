import ThemedProgressBarWithLabel from "../../UI/progressBar/ThemedProgressBarWithLabel";
import myprofil from '../../../assets/images/skillscloud-light-min.png';

export default function Skills(props) {
    return (
        <section id="competences">
            <div className="container">
                <div className="skills-flex-container">
                    <div className="skills-progress">
                        <h2 className="module-title">
                            Compétences
                            <span className="shadow-title">Compétences</span>
                        </h2>
                        <p className="skills-introduction">Bien que spécialisé Back-end et PHP, mes expériences professionnelles m'ont permis de développer des compétences techniques variées et une polyvalence certaine.</p>
                        <ThemedProgressBarWithLabel containerColor="#bbb" value={85}>PHP, MySQL/MariaDB, Symfony</ThemedProgressBarWithLabel>
                        <ThemedProgressBarWithLabel containerColor="#bbb" value={80}>HTML5, CSS3, Sass, BootStrap</ThemedProgressBarWithLabel>
                        <ThemedProgressBarWithLabel containerColor="#bbb" value={65}>Javascript, Jquery, React</ThemedProgressBarWithLabel>
                        {/*                         <ThemedProgressBarWithLabel containerColor="#bbb" value={62}>Git, Composer, npm</ThemedProgressBarWithLabel> */}
                    </div>
                    <div className="skills-cloud">
                        <img src={myprofil} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}