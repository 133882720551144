import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './_progress_bar.scss';

const ProgressBar = ({ value, max = 100, containerColor = "lightgrey", progressColor, height = 20 }) => {
    const [width, setWidth] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const percentage = (value / max) * 100;
                    setWidth(percentage);
                    observer.unobserve(ref.current);
                }
            });
        });

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [value, max]);

    return (
        <div className="progress-bar-container" ref={ref} style={{ backgroundColor: containerColor, height: `${height}px` }}>
            <div className="progress-bar" style={{ width: `${width}%`, backgroundColor: progressColor }}></div>
        </div>
    );
};

ProgressBar.propTypes = {
    value: PropTypes.number,
    max: PropTypes.number,
    containerColor: PropTypes.string,
    progressColor: PropTypes.string,
    height: PropTypes.number
}

export default ProgressBar;
