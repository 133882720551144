import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MyModal from '../modal/MyModal';
import ThemedButtonEffect from "../../UI/buttons/ThemedButtonEffect";
import './_card.scss';


const Card = ({ item, ...props }) => {
    const cardRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [modal, setModal] = useState(false);
    const [previousActiveElement, setPreviousActiveElement] = useState(null);
    const resumeData = item.resume.split(",");

    const handleOpenModal = () => {
        setPreviousActiveElement(document.activeElement);
        setModal(true);
    }

    const handleCloseModal = () => {
        setModal(false);
        if (previousActiveElement) {
            previousActiveElement.focus();
        }
    }

    /**
     * This function prevents focus from moving outside a modal when it is open, and handles tab key presses to cycle focus within the modal.
     * 
     * @param {KeyboardEvent} event 
     */
    const handleTabKey = (event) => {
        if (event.key === "Tab") {
            const modalContainer = document.querySelector(".modal-container");
            const modalElements = modalContainer.querySelectorAll(
                "a[href], button, textarea, input[type='text'], input[type='radio'], input[type='checkbox'], select"
            );
            const firstModalElement = modalElements[0];
            const lastModalElement = modalElements[modalElements.length - 1];

            if (document.activeElement === lastModalElement && !event.shiftKey) {
                event.preventDefault();
                firstModalElement.focus();
            } else if (document.activeElement === firstModalElement && event.shiftKey) {
                event.preventDefault();
                lastModalElement.focus();
            }
        }
    };

    /**
     * Lazy Loading
     */
    useEffect(() => {

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(cardRef.current);
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        observer.observe(cardRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (modal) {
            document.addEventListener("keydown", handleTabKey);
        } else {
            document.removeEventListener("keydown", handleTabKey);
        }
    }, [modal]);


    return (
        <>
            <div className={`card ${(isVisible) ? 'visible' : ''}`} ref={cardRef}>
                <img src={item.img} alt={item.title} loading="lazy" />
                <div className="card-body">
                    <h3 className="card-title">{item.title} </h3>
                    {item.resume ? <p className='card-resume text-center'>{item.resume}</p> : ''}
                    <button aria-label="Voir plus de détails" className="card-more link-style" onClick={handleOpenModal} tabIndex="0">(voir plus d'infos)</button>
                </div>
                {modal && <MyModal isOpen={modal} close={handleCloseModal}>
                    <h2 className="text-center module-title">{item.title}
                        <span className="shadow-title">{item.title}</span></h2>
                    <hr className="card-modal-hr" />
                    <div className='card-modal-flex'>

                        <img src={item.img} alt={item.title} />
                        <p>
                            {item.description}
                        </p>
                        <div className="card-modal-details">
                            <ul>
                                {resumeData.map((element, i) => <li key={i}>{element}</li>)}
                            </ul>
                        </div>
                        <div className="text-center">
                            {item.url ? <a href={item.url}><ThemedButtonEffect>Visiter le site</ThemedButtonEffect></a> : 'Indisponible'}
                        </div>

                    </div>

                </MyModal>}
            </div >
        </>
    );
};

Card.propTypes = {
    img: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    resume: PropTypes.string,
    description: PropTypes.string
}

export default Card;